<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> الاستشارة رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>
          <CAlert color="info">
            <CRow>
              <CCol col="3">حالة الاستشارة</CCol>
              <CCol col="3"> {{ itemData.status_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">المحامي</CCol>
              <CCol col="3"> {{ itemData.lawyer_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">موبايل المحامي</CCol>
              <CCol col="3"> {{ itemData.lawyer_phone }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">العميل</CCol>
              <CCol col="3"> {{ itemData.client_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3">موبايل العميل</CCol>
              <CCol col="3"> {{ itemData.client_phone }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> المكتب </CCol>
              <CCol col="3"> {{ itemData.office_name }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> السعر الاساسي </CCol>
              <CCol col="3"> {{ itemData.sub_price }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> السعر النهائي </CCol>
              <CCol col="3"> {{ itemData.final_price }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> ميعاد الاستشارة </CCol>
              <CCol col="3"> {{ itemData.meeting_date }} </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> لينك العميل </CCol>
              <CCol col="3">
                <a target="_blank" :href="itemData.zoom_client_url">
                {{ itemData.zoom_client_url }}</a>
              </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3"> لينك المحامي </CCol>
              <CCol col="3"> 
                <a target="_blank" :href="itemData.zoom_lawyer_url">
                  {{ itemData.zoom_lawyer_url }}</a>
              </CCol>
            </CRow>
          </CAlert>
          
        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>
        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<script>
// import consultationsData from "./ConsultationsData";
export default {
  name: "Consultation",
  data() {
    return {
      itemData: null,
      consultationsOpened: null,
    };
  },
  created() {
    this.getConsultation();
  },
  methods: {
    getConsultation() {
      this.toggleLoadSpinner();

      this.$http
        .get("consultations/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.consultationsOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/consultations" });
    },
    getBadge(status) {
      switch (status) {
        case "Active":
          return "success";
        case "Inactive":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        default:
          "primary";
      }
    },
  },
};
</script>
